<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG CREAR / EDITAR  -->
         <v-dialog v-model="dialogCreateEdit" persistent max-width="550px">
            <v-form
               ref="formCreateEdit"
               v-model="validCreateEdit"
               lazy-validation
            >
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">
                                 {{ formTitle }}
                              </span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm12>
                              <v-autocomplete
                                 v-model="selectArancel"
                                 :items="cmbArancel"
                                 color="primary"
                                 item-text="nombre"
                                 item-value="codigo"
                                 :rules="rulesArancel"
                                 label="Arancel"
                                 no-data-text="Sin resultados"
                                 placeholder="Seleccionar..."
                              >
                                 <template #item="{ item }">
                                    <v-list-item-content style="width: 0px">
                                       <v-list-item-title
                                          class="wrap-list-title"
                                          v-text="item.nombre"
                                       ></v-list-item-title>
                                       <v-list-item-subtitle
                                          v-text="'Monto ' + item.precio"
                                       ></v-list-item-subtitle>
                                    </v-list-item-content>
                                 </template>
                              </v-autocomplete>
                           </v-flex>
                           <v-flex xs12 sm5>
                              <DateTimePicker
                                 v-model="fechaInicio"
                                 label="Fecha inicio"
                                 :text-field-props="textFieldProps"
                                 :date-picker-props="datePickerProps"
                                 :time-picker-props="timePickerProps"
                                 ok-text="Guardar"
                                 clear-text="Limpiar"
                                 time-format="HH:mm:ss"
                              />
                           </v-flex>
                           <v-flex xs12 sm5>
                              <DateTimePicker
                                 v-model="fechaFin"
                                 label="Fecha
                              fin"
                                 :text-field-props="textFieldProps"
                                 :min-date-props="fechaInicioHelper"
                                 :date-picker-props="datePickerProps"
                                 :time-picker-props="timePickerProps"
                                 ok-text="Guardar"
                                 clear-text="Limpiar"
                                 time-format="HH:mm:ss"
                              />
                           </v-flex>
                           <v-flex xs12 sm2>
                              <v-text-field
                                 v-model="diasHabiles"
                                 color="primary"
                                 label="Dias hábiles"
                                 :rules="rulesDiasHabiles"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-autocomplete
                                 v-model="selectCarrera"
                                 :items="cmbCarreraUniversitaria"
                                 color="primary"
                                 item-text="nombre"
                                 item-value="carreraUniversitariaId"
                                 clearable
                                 label="Aplicar solo a carreras"
                                 no-data-text="Sin resultados"
                                 placeholder="Seleccionar..."
                              >
                                 <template #item="{ item }">
                                    <v-list-item-content style="width: 0px">
                                       <v-list-item-title
                                          class="wrap-list-title"
                                          v-text="item.nombre.trim()"
                                       ></v-list-item-title>
                                    </v-list-item-content>
                                 </template>
                              </v-autocomplete>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-switch
                                 v-model="isExclusion"
                                 :readonly="editedIndex == 1"
                                 inset
                                 label="Es una Exclusión"
                              ></v-switch>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validCreateEdit"
                        @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ELIMINAR -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover arancel periodo</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro el arancel por periodo?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <div style="font-size: 18px">Arancel:</div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ selectArancel }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12>
                                    <div style="font-size: 18px">Nombre:</div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ arancelNombre }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">
                                       Fecha Inicio:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ fechaInicio }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">
                                       Fecha Fin:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ fechaFin }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="removerArancelPeriodo"
                        >Si, eliminar</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Arancel por periodos</v-toolbar-title
               >
               <div class="mt-6 ml-3" style="width: 90px">
                  <v-autocomplete
                     v-model="selectAnnio"
                     :items="cmbAnnios"
                     color="white"
                     label="Año"
                     no-data-text="Sin resultados"
                     placeholder="Seleccionar..."
                  >
                  </v-autocomplete>
               </div>
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
               <v-btn
                  text
                  icon
                  color="white darken-3"
                  @click="nuevoarancelPeriodos()"
               >
                  <v-icon>fas fa-plus</v-icon>
               </v-btn>
            </v-toolbar>

            <!-- DATATABLE -->
            <v-data-table
               :headers="headers"
               :items="arancelPeriodos"
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #item.activo="{ item }">
                  <v-tooltip
                     v-if="item.activo"
                     top
                     max-width="300"
                     color="primary"
                  >
                     <template #activator="{ on }">
                        <v-icon color="primary" v-on="on"
                           >fas fa-toggle-on</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> Si </span>
                  </v-tooltip>
                  <v-tooltip v-else top max-width="300" color="grey darken-1">
                     <template #activator="{ on }">
                        <v-icon color="grey" v-on="on"
                           >fas fa-toggle-off</v-icon
                        >
                     </template>
                     <span style="font-size: 15px"> No </span>
                  </v-tooltip>
               </template>

               <template #item.carreraUniversitariaCodigo="{ item }">
                  <v-tooltip top max-width="300" color="primary">
                     <template #activator="{ on }">
                        <span v-on="on">
                           {{
                              item.carreraUniversitariaCodigo == null
                                 ? 'Todas'
                                 : item.carreraUniversitariaCodigo
                           }}
                        </span>
                     </template>
                     <span style="font-size: 15px">
                        {{ getNombreCarrera(item.carreraUniversitaria) }}
                     </span>
                  </v-tooltip>
               </template>

               <template #item.carreraExcluidaCodigo="{ item }">
                  <v-tooltip top max-width="300" color="primary">
                     <template #activator="{ on }">
                        <span v-on="on">
                           {{
                              item.carreraExcluidaCodigo == null
                                 ? '-'
                                 : item.carreraExcluidaCodigo
                           }}
                        </span>
                     </template>
                     <span style="font-size: 15px">
                        {{ getNombreCarrera(item.carreraExcluida, true) }}
                     </span>
                  </v-tooltip>
               </template>

               <template #item.opciones="{ item }">
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="primary"
                           v-on="on"
                           @click="deleteItem(item)"
                        >
                           <v-icon>fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span>Eliminar</span>
                  </v-tooltip>
               </template>

               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="arancelPeriodos.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import DateTimePicker from './custom/DateTimePicker'
import formatters from '../mixins/formattersLib'
import { mask } from 'vue-the-mask'

export default {
   name: 'ArancelPeriodos',
   directives: {
      mask
   },
   components: {
      DateTimePicker
   },
   mixins: [formatters],

   data: (vm) => ({
      // Variables
      dialogCreateEdit: false,
      dialogEliminar: false,
      editedIndex: -1,
      textFieldProps: {
         prependIcon: 'far fa-calendar-minus'
      },
      datePickerProps: {
         scrollable: true,
         locale: 'es-419',
         color: 'primary'
      },
      timePickerProps: {
         scrollable: true,
         color: 'primary',
         ampmInTitle: true,
         format: 'ampm',
         useSeconds: true
      },
      headers: [
         { text: 'Código', value: 'arancelCodigo', class: 'titulo' },
         { text: 'Arancel', value: 'arancel', class: 'titulo', width: 350 },
         {
            text: 'Fecha Inicial',
            value: 'fechaInicio',
            class: 'titulo',
            align: 'center',
            width: 150
         },
         {
            text: 'Fecha Fin',
            value: 'fechaFin',
            class: 'titulo',
            align: 'center',
            width: 150
         },
         {
            text: 'Dias hábiles',
            value: 'diasHabiles',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Carreras',
            value: 'carreraUniversitariaCodigo',
            class: 'titulo',
            align: 'center'
         },
         {
            text: 'Exclusión',
            value: 'carreraExcluidaCodigo',
            class: 'titulo',
            align: 'center'
         },
         { text: 'Activo', value: 'activo', class: 'titulo', align: 'center' },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      arancelPeriodos: [],

      validCreateEdit: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',

      // Propiedades arancelPeriodos
      arancelPeriodoId: '',
      arancelNombre: '',
      diasHabiles: 2,
      selectCarrera: null,
      isExclusion: false,
      carreraUniversitariaNombre: '',
      cmbCarreraUniversitaria: [],
      activo: false,
      selectArancel: '',
      cmbArancel: [],
      selectAnnio: 0,
      cmbAnnios: [],
      fechaFin: vm.toISOLocal(new Date()).substr(0, 19).replace('T', ' '),
      fechaInicio: vm.toISOLocal(new Date()).substr(0, 19).replace('T', ' '),
      fechaInicioHelper: vm
         .toISOLocal(new Date())
         .substr(0, 19)
         .replace('T', ' '),

      // Reglas de validacion de formularios
      rulesArancel: [(v) => !!v || 'Seleccione un arancel'],
      rulesDiasHabiles: [(v) => !!v || 'Ingrese los dias hábiles']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Nuevo arancel por periodo'
            : 'Editar arancel por periodo'
      }
   },
   watch: {
      selectAnnio() {
         this.listarArancelPeriodos()
      },
      fechaInicio(val) {
         //Modificando Fecha Minima en DateTimePicker Component
         this.fechaInicioHelper = this.toISOLocal(new Date(val))
            .substr(0, 19)
            .replace('T', ' ')
            .split(' ')[0]
      }
   },
   created() {
      //Validando acceso al componente
      if (this.permController(16, 'ver')) {
         this.listarArancelPeriodos()
         this.listarComboBoxes()

         let endDate = new Date()
         endDate.setDate(endDate.getDate() + 1)
         this.fechaFin = this.toISOLocal(new Date(endDate))
            .substr(0, 19)
            .replace('T', ' ')
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      removerArancelPeriodo() {
         // Enviando metodo delete
         axios
            .delete('api/ArancelPeriodo/' + this.arancelPeriodoId)
            .then(() => {
               this.listarArancelPeriodos()
               this.close()
               this.snackText = 'Arancel periodo removido exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((error) => {
               console.log(error)
            })
      },
      getNombreCarrera(carreraUniversitaria, exclusion = false) {
         //Obteniendo el nombre de la carrera e identificando si es una carrera excluida
         if (carreraUniversitaria == null && !exclusion) {
            return 'Todas'
         } else if (carreraUniversitaria == null && exclusion) {
            return 'Ninguna'
         } else {
            return carreraUniversitaria
         }
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarArancelPeriodos() {
         axios
            .get('api/ArancelPeriodo?Year=' + this.selectAnnio)
            .then((response) => {
               this.arancelPeriodos = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las el datatable se puede resetear
      reset() {
         this.listarArancelPeriodos()
      },
      // Select an item
      assingItem(item) {
         if (item != null) {
            this.arancelPeriodoId = item.arancelPeriodoId
            this.selectArancel = item.arancelCodigo
            this.arancelNombre = item.arancel
            this.fechaInicio = `${this.parseDate(
               item.fechaInicio.split(' ')[0]
            )} ${item.fechaInicio.split(' ')[1]}`
            this.fechaFin = `${this.parseDate(item.fechaFin.split(' ')[0])} ${
               item.fechaFin.split(' ')[1]
            }`
            this.diasHabiles = item.diasHabiles
            this.selectCarrera = item.carreraUniversitariaId
            this.isExclusion = item.carreraExcluidaId == 0 ? false : true
            this.selectCarrera =
               item.carreraExcluidaId == 0
                  ? item.carreraUniversitariaId
                  : item.carreraExcluidaId
            this.carreraUniversitariaNombre = item.carreraUniversitariaNombre
            this.activo = item.activo
         } else {
            let endDate = new Date()
            endDate.setDate(endDate.getDate() + 1)
            this.arancelPeriodoId = ''
            this.selectArancel = ''
            this.arancelNombre = ''
            this.fechaInicio = this.toISOLocal(new Date())
               .substr(0, 19)
               .replace('T', ' ')
            this.fechaFin = this.toISOLocal(new Date(endDate))
               .substr(0, 19)
               .replace('T', ' ')
            this.diasHabiles = 2
            this.selectCarrera = null
            this.carreraUniversitariaNombre = ''
            this.activo = false
         }
      },
      // Cargando informacion del item a editar
      editItem(item) {
         this.assingItem(item)
         this.editedIndex = 1
         this.dialogCreateEdit = true
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover unaarancelPeriodos
      deleteItem(item) {
         this.dialogEliminar = true
         this.assingItem(item)
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogCreateEdit = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar arancelPeriodos
      save() {
         if (this.$refs.formCreateEdit.validate()) {
            //Comparando Fecha y Hora Inicio|Finalización
            let start = new Date(this.fechaInicio)
            let end = new Date(this.fechaFin)
            let isDateValid = start.getTime() < end.getTime()
            if (!isDateValid) {
               this.snackText =
                  'Fecha y hora de finalización debe ser mayor a la de Inicio!'
               this.snackColor = 'snackError'
               this.snackbar = true
            } else {
               const objInfoArancel = {
                  arancelCodigo: this.selectArancel,
                  fechaInicio: this.fechaInicio,
                  fechaFin: this.fechaFin,
                  diasHabiles: this.diasHabiles
               }

               this.isExclusion
                  ? (objInfoArancel['carreraExcluidaId'] = this.selectCarrera)
                  : (objInfoArancel['carreraUniversitariaId'] =
                       this.selectCarrera)

               if (this.editedIndex > -1) {
                  // Codigo para actualizar/editar
                  objInfoArancel['arancelPeriodoId'] = this.arancelPeriodoId

                  axios
                     .put(
                        'api/ArancelPeriodo/' + this.arancelPeriodoId,
                        objInfoArancel
                     )
                     .then(() => {
                        this.listarArancelPeriodos()
                        this.close()
                        this.snackText = 'Arancel periodo editado exitosamente!'
                        this.snackColor = 'snackSuccess'
                        this.snackbar = true
                     })
                     .catch((error) => {
                        if (error.response.status == 304) {
                           this.snackText = 'Sin cambios.'
                           this.snackColor = 'snackInfo'
                        } else {
                           console.log(error)
                           this.snackText = 'Algo a ido mal...'
                           this.snackColor = 'snackError'
                        }
                        this.snackbar = true
                        this.close()
                     })
               } else {
                  // Codigo para guardar
                  axios
                     .post('api/ArancelPeriodo', objInfoArancel)
                     .then(() => {
                        this.listarArancelPeriodos()
                        this.close()
                        this.snackText =
                           'Arancel periodo agregado exitosamente!'
                        this.snackColor = 'snackSuccess'
                        this.snackbar = true
                     })
                     .catch((error) => {
                        console.log(error.response)
                        this.snackText = 'No se pudo crear el arancel periodo.'
                        this.snackColor = 'snackError'
                        this.snackbar = true
                     })
               }
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formCreateEdit.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.assingItem(null)
         this.isExclusion = false
      },
      nuevoarancelPeriodos() {
         this.dialogCreateEdit = true
      },
      listarComboBoxes() {
         // Obteniendo sub categorias
         axios
            .get('api/ciclos/years')
            .then((response) => {
               this.cmbAnnios = response.data
               this.selectAnnio = this.cmbAnnios[this.cmbAnnios.length - 1]
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/Arancel')
            .then((response) => {
               this.cmbArancel = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/general/CarreraUniversitaria')
            .then((response) => {
               this.cmbCarreraUniversitaria = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      }
   }
}
</script>
